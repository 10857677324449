import React from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';

import Quote from 'website_quoter/Quote';
import LeadModal from 'website_quoter/LeadModal';

import AmbestInfo from 'shared/AmbestInfo';
import Button from 'shared/Button';
import Modal from 'shared/Modal';
import Select from 'shared/Select';
import ZeroState from 'shared/ZeroState';

import { SORT_OPTIONS, SORT_DIRECTION } from 'constants/App';

import { isMobile } from 'utils/Browser';
import { getRedirectUrl } from 'utils/FormIntegrations';
import WebsiteQuoterApiUtils from 'utils/WebsiteQuoterApi';

class Results extends React.Component {
  static propTypes = {
    Lead: PropTypes.object,
    onZeroStateClick: PropTypes.func,
    quoter: PropTypes.object,
    quotes: PropTypes.array,
    selectedConfig: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      sort_by: SORT_OPTIONS[0],
      sort_direction: SORT_DIRECTION[0],

      show_lead_form_modal: false,
      show_ambest_modal: false,
      loading_ambest: false,

      ambest_record: null,
      quote: null
    };
  }

  _handleFieldChange = (key, e) => {
    this.setState({
      [key]: e.target.value
    });
  };

  _handleSortByChange = e => {
    this.setState({
      sort_by: SORT_OPTIONS.filter(so => so.value === e.target.value)[0]
    });
  };

  _handleOrderByChange = e => {
    this.setState({
      sort_direction: SORT_DIRECTION.filter(sd => sd.value === e.target.value)[0]
    });
  };

  _handleQuoteButtonClick = (button, quote) => {
    const action = button.action_type;

    if (action === 'ixn_lead_form' || action === 'insureio' || action === 'smart_office') {
      this.setState({
        quote,
        show_lead_form_modal: true
      });
    } else {
      const { first_name, last_name, street, city, zip_code, home_phone, cell_phone, work_phone, email, referrer_url } = this.props.Lead;
      window.open(
        getRedirectUrl({
          action: {
            type: action,
            url: button.url
          },
          quote: Object.assign({}, quote, {
            first_name,
            last_name,
            street,
            city,
            zip_code,
            home_phone,
            cell_phone,
            work_phone,
            email,
            referrer_url
          }),
          selected_config: this.props.selectedConfig
        })
      );
    }
  };

  _handleAmbestClick = quote => {
    this.setState({
      show_ambest_modal: true,
      loading_ambest: true
    });

    WebsiteQuoterApiUtils.loadAmbestRecords(quote.carrier_id)
      .then(ambest_records => {
        this.setState({
          ambest_record: ambest_records[0],
          loading_ambest: false
        });
      })
      .catch(() => {
        this.setState({
          loading_ambest: false
        });
      });
  };

  _getSortOptions = () => {
    const sort_by_values = this.state.sort_by.value.split('-');
    const attr = sort_by_values[0] === 'premium' ? `${sort_by_values[0]}_monthy` : sort_by_values[0];
    const direction = this.state.sort_direction.value;

    return { attr, direction };
  };

  render() {
    const styles = this.styles();
    const sort_by = this._getSortOptions();
    const sorted_quotes = _orderBy(this.props.quotes, sort_by.attr, sort_by.direction);

    return (
      <div style={styles.results}>
        {this.state.show_lead_form_modal ? (
          <LeadModal
            fields={this.props.quoter.fields}
            lead={this.props.Lead}
            onClose={() => {
              this.setState({ show_lead_form_modal: false, quote: null });
            }}
            onConfirm={() => {
              this.setState({ show_lead_form_modal: false, quote: null });
            }}
            quote={this.state.quote}
            successMessage={this.props.quoter.lead_form_success_message}
          />
        ) : null}

        {this.state.show_ambest_modal ? (
          <Modal
            maxWidth={800}
            onClose={() => {
              this.setState({ show_ambest_modal: false, ambest_record: null });
            }}
            title='AM Best Information'
          >
            <div style={{ padding: 40 }}>
              <AmbestInfo details={this.state.ambest_record} />
            </div>
          </Modal>
        ) : null}

        {sorted_quotes.length ? (
          <div>
            <div style={styles.filters}>
              <div style={styles.filter}>
                <Select defaultValue={this.state.sort_by.value} onChange={this._handleSortByChange} options={SORT_OPTIONS} />
              </div>
              <div style={styles.filter}>
                <Select defaultValue={this.state.sort_direction.value} onChange={this._handleOrderByChange} options={SORT_DIRECTION} />
              </div>
            </div>

            <div style={styles.quotes}>
              {sorted_quotes.map((quote, i) => {
                return (
                  <Quote
                    key={i}
                    onAmbestClick={this._handleAmbestClick}
                    onButtonClick={this._handleQuoteButtonClick}
                    quote={quote}
                    quoter={this.props.quoter}
                    selectedConfig={this.props.selectedConfig}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <ZeroState
            message={
              <div>
                <div>We couldn't find any matching products for the information you provided.</div>
                <Button onClick={this.props.onZeroStateClick} style={{ fontSize: 13, marginTop: 30 }}>
                  Adjust Your Information
                </Button>
              </div>
            }
          />
        )}
      </div>
    );
  }

  styles = () => {
    return {
      results: {
        backgroundColor: 'rgb(245, 245, 245)'
      },
      quotes: {
        padding: 20
      },

      // FILTERS
      filters: {
        display: isMobile ? 'block' : 'flex',
        justifyContent: 'flex-end',
        marginRight: 0,
        marginLeft: 'auto',
        padding: 20,
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        boxShadow: '0px 5px 20px -15px rgba(0,0,0,0.2)'
      },
      filter: {
        display: isMobile ? 'block' : 'inline-block',
        marginLeft: isMobile ? 0 : 15,
        marginBottom: isMobile ? 15 : 0
      }
    };
  };
}

export default Results;
