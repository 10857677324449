import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import Recaptcha from 'react-recaptcha';
import _orderBy from 'lodash/orderBy';
import _snakeCase from 'lodash/snakeCase';
import _trimStart from 'lodash/trimStart';
import _startCase from 'lodash/startCase';

import FlashMessage from 'website_quoter/FlashMessage';
import Header from 'website_quoter/Header';
import Results from 'website_quoter/Results';
import ResultsModal from 'website_quoter/ResultsModal';

import Button from 'shared/Button';
import DatePicker from 'shared/DatePicker';
import Field from 'shared/Field';
import Input from 'shared/Input';
import Legal from 'shared/Legal';
import Loader from 'shared/Loader';
import Select from 'shared/Select';
import ZeroState from 'shared/ZeroState';

import { GENDERS, STATES, TOBACCO_OPTIONS, TOBACCO, NON_TOBACCO } from 'constants/App';

import { isMobile } from 'utils/Browser';
import WebsiteQuoterApiUtils from 'utils/WebsiteQuoterApi';
import FormUtils from 'utils/Form';

import { Colors, FontSizes } from 'constants/Clementine';

import { ThemeContext } from 'shared/ThemeContext';
import Notification from '../components/Notifications/Toasts';

const WQ_CONFIG = window.IXN_QUOTER_CONFIG;
const PROMO_CODE = WQ_CONFIG.promo_code;

class WebsiteQuoter extends React.Component {
  constructor(props) {
    super(props);

    this.recaptchaInstance = null;
    this.state = {
      loading_errors: [],

      loading_quoter: true,
      loading_quotes: false,
      show_config: true,
      show_results: false,
      errors: [],

      google_recaptcha_site_key: null,
      gr_token: null,

      state: 'AL',
      gender: 'Male',
      tobacco: false,
      date_of_birth: '1980-01-01',
      face_amount: 200000,
      health_category: 'Standard',
      product_type: '20 Year Term',

      help_content: null,
      quotes: [],
      quoter: {
        fields: []
      },

      Lead: {}
    };
  }

  componentDidMount() {
    WebsiteQuoterApiUtils.loadWebsiteQuoter().then(response => {
      if (!response) {
        this.setState({
          loading_quoter: false,
          loading_errors: [{ title: 'An unknown error occurred.' }]
        });
      } else if (response.errors) {
        this.setState({
          loading_quoter: false,
          loading_errors: response.errors
        });
      } else {
        this.setState({
          quoter: response,
          loading_quoter: false,

          google_recaptcha_site_key: response.google_recaptcha_site_key,

          face_amount: response.default_face_amount || 200000,
          state: response.default_state || response.states[0] || 'AL',
          health_category: response.default_health_category || 'Standard',
          product_type: response.default_product_type || response.term_products[0] || '20 Year Term',
          term_products: response.term_products || [],
          return_of_premium_products: response.return_of_premium_products || [],
          guaranteed_life_products: response.guaranteed_life_products || [],
          other_products: response.other_products || [],
          Lead: { promo_code: PROMO_CODE || response.smart_office_promo_code } // Local promo code precedes lead settings
        });
      }
    });

    this.setState({
      is_small: this.quoter_el.offsetWidth < 600 || isMobile
    });
  }

  _handleFieldChange = (key, e) => {
    const errors = this.state.errors;
    const formatted_key = _startCase(key.replace('lead_', '')); // set key to same format as errors
    const filtered_errors = errors.filter(e => e !== formatted_key); // remove formatted_key from errors

    this.setState({
      errors: filtered_errors,
      [key]: _trimStart(e.target.value)
    });
  };

  _handleDobChange = date_of_birth => {
    this.setState({ date_of_birth });
  };

  _handleTobaccoChange = () => {
    this.setState({
      tobacco: !this.state.tobacco,
      health_category: 'Standard'
    });
  };

  _handleRecaptcha = (gr_token = null) => {
    this.setState({ gr_token });
  };

  _handleViewQuotesClick = () => {
    let has_lead_fields = false;
    const should_verify = !!this.state.google_recaptcha_site_key;
    const errors = [];
    const lead_details = {
      agency_id: this.state.quoter.agency_id,
      date_of_birth: this.state.date_of_birth,
      face_amount: this.state.face_amount,
      gender: this.state.gender,
      health_category: this.state.health_category,
      member_ids: this.state.quoter.member_id ? [this.state.quoter.member_id] : [],
      product_type: this.state.product_type,
      promo_code: this.state.Lead.promo_code,
      referrer_url: window.location.href,
      source: 'WebsiteQuoter',
      source_guid: window.IXN_QUOTER_CONFIG.id,
      state: this.state.state,
      status: 'unassigned',
      tobacco: this.state.tobacco === true
    };

    if (!lead_details.face_amount) {
      errors.push('face_amount');
    }

    this.state.quoter.fields.forEach(field => {
      if (field.is_visible_on_config) {
        has_lead_fields = true;
        let name = `lead_${_snakeCase(field.label)}`;
        const value = this.state[name] || '';
        if (field.is_visible_on_config) {
          if (field.is_required && !value.length && field.label !== 'State') {
            errors.push(field.label);
          }

          if (value.length) {
            if (name.includes('email') && !FormUtils._validateEmail(value)) {
              errors.push(field.label);
            }

            if (name.includes('phone') && !FormUtils._validatePhone(value)) {
              errors.push(field.label);
            }
          }
        }
        name = name.replace('lead_', '');
        lead_details[name] = value;
      }
    });

    if (should_verify && !this.state.gr_token) {
      errors.push('reCAPTCHA');
    }

    if (errors.length) {
      this.setState({ errors });
    } else if (should_verify) {
      WebsiteQuoterApiUtils.verifyGoogleRecaptchaToken(this.state.gr_token).then(response => {
        if (response.success) {
          this._getQuotes(has_lead_fields, lead_details);
        } else {
          this.setState({ errors: ['reCAPTCHA'] });
        }
      });
    } else {
      this._getQuotes(has_lead_fields, lead_details);
    }
  };

  _getQuotes = (has_lead_fields, lead_details) => {
    lead_details.state = this.state.state;
    this.setState({
      loading_quotes: true,
      show_config: this.state.quoter.show_results_in_modal,
      show_results: false,
      errors: []
    });

    if (has_lead_fields) {
      WebsiteQuoterApiUtils.saveLead(lead_details).then(response => {
        this.setState({
          Lead: response
        });
      });
    } else {
      this.setState({
        Lead: lead_details
      });
    }

    WebsiteQuoterApiUtils.getQuotes({
      date_of_birth: this.state.date_of_birth,
      face_amount: parseInt(this.state.face_amount, 10),
      gender: this.state.gender,
      health_categories: [this.state.health_category],
      product_types: [this.state.product_type],
      state: this.state.state,
      tobacco: this.state.tobacco === true,
      carrier_ids: this.state.quoter.carrier_ids,
      product_ids: this.state.quoter.product_ids
    }).then(quotes => {
      this.setState({
        quotes,
        loading_quotes: false,
        show_results: true
      });
    });
  };

  _handleHeaderClick = () => {
    this.setState({
      show_config: !this.state.show_config
    });
  };

  _handleResultsClose = () => {
    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
    }

    this.setState({
      show_results: false,
      gr_token: null
    });
  };

  _errorMessages = field => {
    if (field.field_type === 'phone') {
      return help_docs.phone_error;
    } else if (field.field_type === 'email') {
      return help_docs.email_error;
    } else return `${field.label} is a required field`;
  };

  render() {
    const styles = this.styles();
    const accent_color = this.state.quoter.primary_color || Colors.GREEN.hex;
    const color = this.state.quoter.secondary_color || Colors.BLUE.hex;
    const { state, date_of_birth, gender, tobacco, face_amount, health_category, product_type } = this.state;
    const selected_config = { state, date_of_birth, gender, tobacco, face_amount, health_category, product_type };
    const fields = this.state.quoter.fields.filter(f => f.is_visible && f.is_visible_on_config && f.label !== 'State');

    return (
      <ThemeContext.Provider value={{ accent_color, color }}>
        <Notification color={color} message='Welcome to Website Quoter' />
        <div ref={el => (this.quoter_el = el)}>
          {this.state.loading_quoter ? (
            <Loader />
          ) : (
            <React.Fragment>
              {this.state.loading_errors.length ? (
                <ZeroState
                  icon='mdi-alert-circle-outline'
                  message={
                    <div>
                      {this.state.loading_errors[0].title} Please contact IXN Support at <a href='mailto:support@ixntech.com'>support@ixntech.com</a>.
                    </div>
                  }
                  style={styles.quoter_zero_state}
                />
              ) : (
                <div style={styles.quoter}>
                  <Header onClick={this._handleHeaderClick} style={styles.header}>
                    <div>Your Information</div>

                    {this.state.show_config ? (
                      <div style={styles.header_text}>
                        Hide <i className='mdi mdi-minus-circle' style={styles.header_icon} />
                      </div>
                    ) : (
                      <div style={styles.header_text}>
                        Show <i className='mdi mdi-plus-circle' style={styles.header_icon} />
                      </div>
                    )}
                  </Header>

                  {this.state.loading_quotes ? <Loader /> : null}

                  {this.state.show_config && !this.state.loading_quotes ? (
                    <div style={styles.config}>
                      {this.state.errors.length ? <FlashMessage style={styles.flash_message}>Please fill out all required fields, or correct any fields containing errors.</FlashMessage> : null}

                      <div style={styles.fields}>
                        <div style={styles.quote_fields}>
                          <Field label='State' tooltip={help_docs.state}>
                            <Select
                              onChange={this._handleFieldChange.bind(null, 'state')}
                              options={STATES.filter(s => this.state.quoter.states.includes(s.value))}
                              required={true}
                              value={this.state.quoter.states.includes(this.state.state) ? this.state.state : this.state.quoter.states[0]}
                            />
                          </Field>

                          <Field label='Date of Birth' tooltip={help_docs.date_of_birth}>
                            <DatePicker
                              hidePlaceholders={true}
                              invalid={this.state.errors.includes('date_of_birth')}
                              onChange={this._handleDobChange}
                              required={true}
                              value={this.state.date_of_birth}
                            />
                          </Field>

                          <Field label='Gender' tooltip={help_docs.gender}>
                            <Select onChange={this._handleFieldChange.bind(null, 'gender')} options={GENDERS} required={true} value={this.state.gender} />
                          </Field>

                          <Field label='Tobacco User?' tooltip={help_docs.tobacco_user}>
                            <Select onChange={this._handleTobaccoChange} options={TOBACCO_OPTIONS} required={true} value={this.state.tobacco} />
                          </Field>

                          <Field color='red' label='Your Health' tooltip={help_docs.your_health} tooltipWidth={450}>
                            <Select
                              onChange={this._handleFieldChange.bind(null, 'health_category')}
                              options={(this.state.tobacco === true ? TOBACCO : NON_TOBACCO).map(health_category => {
                                return {
                                  value: health_category
                                };
                              })}
                              required={true}
                              value={this.state.health_category}
                            />
                          </Field>

                          <Field label='Length of Coverage' tooltip={help_docs.term_length}>
                            <Select
                              onChange={this._handleFieldChange.bind(null, 'product_type')}
                              options={[].concat(this.state.term_products, this.state.return_of_premium_products, this.state.guaranteed_life_products, this.state.other_products).map(product => {
                                return {
                                  value: product
                                };
                              })}
                              required={true}
                              value={this.state.product_type}
                            />
                          </Field>

                          <Field label='Coverage Amount' tooltip={help_docs.face_amount}>
                            <Input
                              format='currency'
                              invalid={this.state.errors.includes('face_amount')}
                              onChange={this._handleFieldChange.bind(null, 'face_amount')}
                              required={true}
                              value={this.state.face_amount}
                            />
                          </Field>

                          {!fields.length && this.state.google_recaptcha_site_key ? (
                            <div style={Object.assign({}, styles.field, styles.gr_field, this.state.errors.includes('reCAPTCHA') && styles.gr_error)}>
                              <Recaptcha
                                expiredCallback={this._handleRecaptcha}
                                ref={e => (this.recaptchaInstance = e)}
                                sitekey={this.state.google_recaptcha_site_key}
                                verifyCallback={this._handleRecaptcha}
                              />
                            </div>
                          ) : null}
                        </div>

                        {fields.length ? (
                          <div style={styles.lead_fields}>
                            {_orderBy(fields, 'order').map(field => {
                              const name = `lead_${_snakeCase(field.label)}`;

                              return (
                                <Field
                                  key={field.id}
                                  label={field.label}
                                  style={Object.assign({}, styles.field, { width: this.state.is_small ? 100 : `calc(${field.width}% - 5px)` || 100 })}
                                  tooltip={field.help_content}
                                  tooltipError={this.state.errors.includes(field.label)}
                                  tooltipErrorMsg={this._errorMessages(field)}
                                >
                                  <Input
                                    format={field.field_type === 'phone' ? 'phone' : null}
                                    invalid={this.state.errors.includes(field.label)}
                                    onChange={this._handleFieldChange.bind(null, name)}
                                    required={field.is_required}
                                    type={field.field_type}
                                    value={this.state[name] || ''}
                                  />
                                </Field>
                              );
                            })}

                            {this.state.google_recaptcha_site_key ? (
                              <div style={Object.assign({}, styles.field, styles.gr_field, this.state.errors.includes('reCAPTCHA') && styles.gr_error)}>
                                <Recaptcha
                                  expiredCallback={this._handleRecaptcha}
                                  ref={e => (this.recaptchaInstance = e)}
                                  sitekey={this.state.google_recaptcha_site_key}
                                  verifyCallback={this._handleRecaptcha}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>

                      <div style={styles.button}>
                        <Button onClick={this._handleViewQuotesClick} type='primary'>
                          View Quotes
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  {this.state.loading_quotes ? null : (
                    <React.Fragment>
                      {this.state.show_results ? (
                        <React.Fragment>
                          {this.state.quoter.show_results_in_modal ? (
                            <ResultsModal Lead={this.state.Lead} onClose={this._handleResultsClose} quoter={this.state.quoter} quotes={this.state.quotes} selectedConfig={selected_config} />
                          ) : (
                            <React.Fragment>
                              <Header onClick={this._handleHeaderClick} style={{ margin: '1px -1px 0' }}>
                                Results
                              </Header>
                              <Results Lead={this.state.Lead} onZeroStateClick={this._handleHeaderClick} quoter={this.state.quoter} quotes={this.state.quotes} selectedConfig={selected_config} />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  )}

                  <Legal />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </ThemeContext.Provider>
    );
  }

  styles = () => {
    const has_fields = this.state.quoter.fields.some(f => f.is_visible && f.is_visible_on_config && f.label !== 'State');

    return {
      quoter: {
        border: '1px solid #e5e5e5',
        borderRadius: 5
      },
      config: {
        padding: this.state.is_small ? '20px 10px' : 20,
        fontSize: FontSizes.REGULAR,
        fontWeight: 400,
        backgroundColor: '#ffffff'
      },
      header: {
        cursor: 'pointer',
        borderRadius: '5px 5px 0 0',
        margin: '-1px -1px 0',
        display: 'flex'
      },
      header_text: {
        marginRight: 5,
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center'
      },
      header_icon: {
        fill: '#ffffff',
        marginLeft: 5
      },

      help_icon: {
        width: 16,
        height: 16,
        marginTop: -4,
        marginLeft: 5,
        fill: Colors.GREEN.hex,
        cursor: 'pointer'
      },

      // FIELDS
      flash_message: {
        margin: '0 5px 20px'
      },
      fields: {
        display: this.state.is_small ? 'block' : 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      },
      quote_fields: {
        width: this.state.is_small || !has_fields ? '100%' : '49%'
      },
      lead_fields: {
        width: this.state.is_small ? '100%' : '49%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 30
      },
      gr_field: {
        padding: 0,
        margin: '5px 0px 20px 5px',
        display: 'inline-block'
      },
      gr_error: {
        border: '1px solid ' + Colors.RED.hex,
        borderRadius: 3
      }
    };
  };
}

const help_docs = {
  your_health: (
    <div style={{ lineHeight: '1.4em' }}>
      Life Insurance premiums are based on your health rating. This means that the younger and healthier you are, the better your rate will be. There are 4 basic categories. For now, you can follow
      this guide to estimate which is the right one for you. *Note, these descriptions are generally based on people who haven't used any form of tobacco for about 5 years.
      <br />
      <br />
      <table style={{ color: '#fff' }}>
        <tbody>
          <tr>
            <td style={{ width: '25%' }}>Preferred Plus</td>
            <td style={{ width: '25%' }}>Preferred</td>
            <td style={{ width: '25%' }}>Standard Plus</td>
            <td style={{ width: '25%' }}>Standard</td>
          </tr>
          <tr>
            <td style={{ lineHeight: '1.4em', fontWeight: 300 }}>
              Exceptional Health, lowest rates. This category is a tough one to get into. It's generally for those at a normal weight for their height, who have normal blood pressure/cholesterol
              readings, and have a clean medical history. Usually you can't have a history of premature death in your family related to heart disease or cancer. Your driving record counts too.
            </td>
            <td style={{ lineHeight: '1.4em', fontWeight: 300 }}>
              Excellent Health, 2nd-best rates. This category is similar to the Preferred Plus, but allows a bit more elbow room on things like blood pressure, cholesterol, and weight.
            </td>
            <td style={{ lineHeight: '1.4em', fontWeight: 300 }}>Above-Average Health. You'll fit in here if you're generally healthy, but don't quite meet the Preferred requirements.</td>
            <td style={{ lineHeight: '1.4em', fontWeight: 300 }}>
              Average Health. Not the lowest, but usually still good rates. This category is for those working on their weight, being treated for high blood pressure or cholesterol, or those with a
              history of premature death from heart disease or cancer in their family.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  term_length: 'This is how long your policy and coverage will last, as long as you continue to make payments. Your rate will be locked in for this entire duration of the policy.',
  face_amount:
    'This is the amount of money that you will leave behind for your loved ones. It might be tempting to lower this amount to get a lower rate, but remember to think realistically about the needs of those that depend on you.',
  phone_error: 'Phone numbers must be 10 digits in length and cannot begin with 0 or 1.',
  tobacco_user: 'Are you a tobacco user?',
  gender: 'Select a gender.',
  date_of_birth: 'Select a month, day, then year.',
  state: 'Select the state where you live.',
  last_name: 'Enter your last name.',
  first_name: 'Enter your first name.',
  email_error: 'Please enter a valid email.'
};

ReactDOM.render(<WebsiteQuoter />, document.getElementById('ixn-website-quoter'));
