import Moment from 'moment';
import _camelCase from 'lodash/camelCase';
import _upperFirst from 'lodash/upperFirst';
import _mapKeys from 'lodash/mapKeys';
import _startCase from 'lodash/startCase';
import _omitBy from 'lodash/omitBy';
import _isNull from 'lodash/isNull';

const excluded_quote_keys = [
  'actions',
  'agent_message',
  'ambest_record_id',
  'band_id',
  'compulife_quote',
  'calculation_type',
  'has_additional_forms',
  'has_agent_approved_marketing_forms',
  'has_client_approved_marketing_forms',
  'has_required_forms',
  'health_category_id',
  'id',
  'logo_filename',
  'modal_factor_id',
  'product_group_id',
  'quote_clicked',
  'quote_list_id',
  'created_at',
  'updated_at',
  'carrier_logo_url',
  'hide_premium_breakdown'
];

export const cleanQuote = function (quote = {}, selected_config = {}, quote_params = [], agent, client) {
  const combined_quote = Object.assign({}, quote, selected_config, client);
  const clean_quote = _omitBy(combined_quote, (v, k) => {
    return excluded_quote_keys.indexOf(k) >= 0 || _isNull(v);
  });

  if (agent) {
    Object.keys(agent).forEach(key => {
      const value = agent[key];

      if (value) {
        clean_quote[`agent_${key}`] = agent[key];
      }
    });
  }

  if (client && client.name) {
    const name_parts = client.name.split(' ');

    if (name_parts[0]) {
      clean_quote.first_name = name_parts[0];
    }

    if (name_parts[1]) {
      clean_quote.last_name = name_parts[1];
    }
  }

  if (selected_config.date_of_birth) {
    const moment_date = Moment(selected_config.date_of_birth);

    clean_quote.dob_day = moment_date.format('DD');
    clean_quote.dob_month = moment_date.format('MM');
    clean_quote.dob_year = moment_date.format('YYYY');
  }

  if (quote_params.length) {
    quote_params.forEach(param => {
      if (param.name && param.value) {
        clean_quote[param.name] = param.value;
      }
    });
  }

  return clean_quote;
};

export function getRedirectUrl(data) {
  const action = data.action || {};
  let url = action.url;
  const clean_quote = cleanQuote(data.quote, data.selected_config, data.quote_params, data.agent, data.client);

  if (action.type === 'formassembly' || action.type === '123_contact_form' || action.type === 'formsite' || action.type === 'wufoo' || action.type === 'google_form') {
    Object.keys(clean_quote).forEach(key => {
      const value = clean_quote[key];

      url = url.replace(`|*${key}*|`, value);
      url = url.replace(`%7C*${key}*%7C`, value);
    });

    // cleans up any remaining variables in the URL
    url = url.replace(/\|\*(.*)\*\|/, '');
    url = url.replace(/\%\7\C\*(.*)\*\%\7\C/, '');
  } else if (action.type === 'cognito_form') {
    const query_object = _mapKeys(clean_quote, (value, key) => {
      // FirstName
      return _upperFirst(_camelCase(key));
    });

    url = `${url}?entry=${JSON.stringify(query_object)}`;
  } else if (action.type === 'simple_url') {
    return action.url; //No data payload
  } else {
    const query_array = Object.keys(clean_quote).map(key => {
      // first_name
      let formatted_key = key;

      if (action.type === 'jotform') {
        // firstName
        formatted_key = _camelCase(key);
      } else if (action.type === 'formstack') {
        // First_Name
        formatted_key = _startCase(key).replace(/ /g, '_');
      }

      return `${formatted_key}=${encodeURIComponent(clean_quote[key])}`;
    });

    url = `${url}?${query_array.join('&')}`;
  }

  return url;
}
