import React from 'react';
import PropTypes from 'prop-types';

import { Colors, FontSizes } from 'constants/Clementine';

const SelectField = props => {
  const styles = {
    component: Object.assign(
      {},
      {
        width: '100%',
        WebkitAppearance: 'none',
        boxShadow: 'none',
        outline: 'none',
        borderRadius: 4,
        color: Colors.GRAY.hex,
        fontSize: FontSizes.MEDIUM,
        fontWeight: 400,
        lineHeight: '40px',
        height: 40,
        background: '#fff',
        position: 'relative',
        cursor: 'pointer',
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: props.invalid ? Colors.RED.hex : '#ccc',
        backgroundColor: props.readOnly ? '#f5f5f5' : '#fff',
        backgroundImage: 'none'
      },
      props.style
    ),
    trigger: {
      backgroundColor: 'transparent',
      border: 'none',
      color: Colors.GRAY.hex,
      fontSize: FontSizes.REGULAR,
      padding: '9px 28px 8px 10px',
      position: 'relative',
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      width: '100%',
      cursor: 'pointer',
      zIndex: 1,
      height: 38,
      lineHeight: 1
    },
    icon: {
      position: 'absolute',
      right: 8,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 18,
      fill: Colors.GRAY.hex,
      zIndex: 0
    },
    required: {
      position: 'absolute',
      lineHeight: '1',
      right: 5,
      top: 5,
      color: Colors.RED.hex,
      fontSize: FontSizes.LARGE
    }
  };

  const _handleOnChange = e => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div id={props.id || 'selection'} onClick={props.onClick} style={styles.component}>
      <select disabled={props.readOnly} onChange={_handleOnChange} placeholder={props.placeholder} style={styles.trigger} value={props.value}>
        {props.placeholder ? (
          <option disabled={true} value=''>
            {props.placeholder}
          </option>
        ) : null}
        {props.optionGroups &&
          props.optionGroups.map((group, i) => {
            return (
              <optgroup key={i} label={group.label || `Group ${i}`}>
                {group.options.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.label || option.value}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        {props.options &&
          props.options.map((option, i) => {
            return (
              <option key={i} value={option.value}>
                {option.label || option.value}
              </option>
            );
          })}
      </select>
      <i className='mdi mdi-chevron-down' style={styles.icon} />
      {props.required ? <span style={styles.required}>*</span> : null}
    </div>
  );
};

SelectField.propTypes = {
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  optionGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
      ).isRequired
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.node
};

export default SelectField;
