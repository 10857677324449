import React from 'react';

import { ThemeContext } from 'shared/ThemeContext';

import { FontSizes } from 'constants/Clementine';

const Legal = () => {
  const context = React.useContext(ThemeContext);
  const styles = {
    legal: {
      padding: 15,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      fontSize: FontSizes.SMALL
    },
    link: {
      color: context.color,
      textDecoration: 'none',
      fontSize: FontSizes.SMALL,
      lineHeight: '24px',
      cursor: 'pointer',
      marginRight: 15
    }
  };

  return (
    <div style={styles.legal}>
      <div style={styles.links}>
        <a href='https://ixn.tech/terms-of-service/' style={styles.link} target='_blank'>
          Terms of Service
        </a>
        <a href='https://ixn.tech/privacy-policy/' style={styles.link} target='_blank'>
          Privacy Policy
        </a>
      </div>
      <div>
        powered by{' '}
        <a href='https://ixn.tech' style={styles.link} target='_blank'>
          IXN
        </a>
      </div>
    </div>
  );
};

export default Legal;
