import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';

import { Colors, FontSizes, FontFamilies } from 'constants/Clementine';

import FormUtils from 'utils/Form';

class Input extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    format: PropTypes.oneOf(['phone', 'currency', 'currency_decimal', 'ssn', 'zip']),
    invalid: PropTypes.bool,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    step: PropTypes.string,
    suffix: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password', 'phone', 'email', 'textarea']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    type: 'text'
  };

  constructor(props) {
    super(props);

    this.state = {
      inputFocus: false
    };
  }

  _handleInputFocus = () => {
    if (!this.props.readOnly) {
      this.setState({ inputFocus: true });
    }
  };

  _handleInputBlur = e => {
    this.setState({ inputFocus: false });

    if (this.props.onBlur) this.props.onBlur(e);
  };

  _handleInputChange = e => {
    if (this.props.format === 'currency' || this.props.format === 'currency_decimal') {
      e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    }

    if (this.props.format === 'phone') {
      e.target.value = e.target.value.replace(/[^0-9.]/g, '').substring(0, 10);
    }

    if (this.props.format === 'ssn') {
      e.target.value = e.target.value.replace(/[^0-9]/g, '').substring(0, 9);
    }

    if (this.props.format === 'zip') {
      e.target.value = e.target.value.replace(/[^0-9]/g, '').substring(0, 5);
    }

    if (this.props.type === 'email') {
      e.target.value = e.target.value.toLowerCase();
    }

    if (this.props.type === 'number' && this.props.max) {
      e.target.value = e.target.value > this.props.max ? this.props.max : e.target.value;
    }

    if (!this.props.readOnly && this.props.onChange) this.props.onChange(e);
  };

  render() {
    const { id, format, maxLength, max, min, placeholder, readOnly, required, step, suffix, type, value } = this.props;
    const styles = this.styles();
    const unformatted_value = value;
    let formatted_value = value;

    if (format === 'phone') {
      formatted_value = FormUtils._formatAsPhone(unformatted_value);
    }

    if (format === 'currency') {
      if (`${unformatted_value}`.length) {
        formatted_value = Numeral(unformatted_value).format('$0,0');
      }
    }

    if (format === 'currency_decimal') {
      formatted_value = `$${formatted_value}`;
    }

    if (format === 'ssn' && type !== 'password') {
      formatted_value = FormUtils._formatAsSsn(unformatted_value);
    }

    return (
      <div style={styles.component}>
        {this.props.type === 'textarea' ? (
          <textarea
            id={id}
            maxLength={maxLength}
            onBlur={this._handleInputBlur}
            onChange={this._handleInputChange}
            onFocus={this._handleInputFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            style={styles.input}
            type={type}
            value={formatted_value}
          />
        ) : (
          <input
            id={id}
            max={max}
            maxLength={maxLength}
            min={min}
            onBlur={this._handleInputBlur}
            onChange={this._handleInputChange}
            onFocus={this._handleInputFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            step={step}
            style={styles.input}
            type={type}
            value={formatted_value}
          />
        )}
        {required ? <span style={styles.required}>*</span> : null}
        {this.props.type !== 'textarea' && suffix ? <div style={styles.suffix}>{suffix}</div> : null}
      </div>
    );
  }

  styles = () => {
    const prop_styles = this.props.style || {};
    let border_color = this.state.inputFocus ? '#aaaaaa' : '#ccc';

    if (this.props.invalid) {
      border_color = Colors.RED.hex;
    }

    return {
      component: Object.assign(
        {
          backgroundColor: this.props.readOnly ? '#f5f5f5' : '#fff',
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: border_color,
          fontSize: FontSizes.MEDIUM,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          height: 40,
          lineHeight: '20px',
          padding: '10px 0'
        },
        prop_styles
      ),
      input: {
        fontSize: FontSizes.MEDIUM,
        fontFamily: FontFamilies.MAIN,
        fontWeight: 400,
        lineHeight: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        color: this.props.readOnly ? '#777' : Colors.GRAY.hex,
        padding: '0 10px',
        margin: 0,
        textIndent: 0,
        boxShadow: 'none',
        outline: 'none',
        width: '100%',
        height: prop_styles.height ? (prop_styles.height || 100) - 20 : 20,
        fontStyle: 'normal',
        WebkitAppearance: 'none'
      },
      required: {
        color: Colors.RED.hex,
        position: 'absolute',
        right: 5,
        top: 5,
        lineHeight: '1',
        fontSize: FontSizes.LARGE
      },
      suffix: {
        background: '#f5f5f5',
        borderLeft: '1px solid #ccc',
        borderRadius: '0 4px 4px 0',
        fontSize: FontSizes.MEDIUM,
        fontFamily: FontFamilies.MAIN,
        fontWeight: 400,
        height: 38,
        color: Colors.GRAY.hex,
        lineHeight: '40px',
        padding: '0px 10px'
      }
    };
  };
}

export default Input;
