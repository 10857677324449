module.exports = {
  FontFamilies: {
    MAIN: "'Roboto', Helvetica, Arial, sans-serif",
    ACCENT: "'Quicksand', 'Roboto', Helvetica, Arial, sans-serif"
  },
  FontSizes: {
    XSMALL: 10,
    SMALL: 12,
    REGULAR: 13,
    MEDIUM: 14,
    LARGE: 16,
    XLARGE: 20,
    XXLARGE: 30
  },
  FontWeights: {
    LIGHT: 300,
    REGULAR: 400,
    MEDIUM: 500,
    BOLD: 700
  },
  Colors: {
    GRAY: {
      hex: '#2F3B44',
      rgb: '47, 59, 68'
    },
    ACCENT: '#52ADFF',
    BLUE: {
      hex: '#52ADFF',
      rgb: '82, 173, 255'
    },
    GREEN: {
      hex: '#1DBC3E',
      rgb: '29, 188, 62'
    },
    RED: {
      hex: '#DF4B4B',
      rgb: '223, 75, 75'
    }
  },
  HealthCategories: {
    primary: ['Preferred Plus', 'Preferred', 'Standard Plus', 'Standard', 'Table A', 'Table B', 'Table C', 'Table D'],
    all: [
      'Preferred Plus',
      'Preferred',
      'Standard Plus',
      'Standard',
      'Table A',
      'Table B',
      'Table C',
      'Table D',
      'Table E',
      'Table F',
      'Table G',
      'Table H',
      'Table I',
      'Table J',
      'Table K',
      'Table L',
      'Table M',
      'Table N',
      'Table O',
      'Table P',
      'Table Q',
      'Table R',
      'Table S',
      'Table T',
      'Table U',
      'Table V'
    ]
  },
  States: {
    AL: {
      label: 'Alabama',
      value: 'AL'
    },
    AK: {
      label: 'Alaska',
      value: 'AK'
    },
    AS: {
      label: 'American Samoa',
      value: 'AS'
    },
    AZ: {
      label: 'Arizona',
      value: 'AZ'
    },
    AR: {
      label: 'Arkansas',
      value: 'AR'
    },
    CA: {
      label: 'California',
      value: 'CA'
    },
    CO: {
      label: 'Colorado',
      value: 'CO'
    },
    CT: {
      label: 'Connecticut',
      value: 'CT'
    },
    DE: {
      label: 'Delaware',
      value: 'DE'
    },
    DC: {
      label: 'District of Columbia',
      value: 'DC'
    },
    FM: {
      label: 'Federated States of Micronesia',
      value: 'FM'
    },
    FL: {
      label: 'Florida',
      value: 'FL'
    },
    GA: {
      label: 'Georgia',
      value: 'GA'
    },
    GU: {
      label: 'Guam',
      value: 'GU'
    },
    HI: {
      label: 'Hawaii',
      value: 'HI'
    },
    ID: {
      label: 'Idaho',
      value: 'ID'
    },
    IL: {
      label: 'Illinois',
      value: 'IL'
    },
    IN: {
      label: 'Indiana',
      value: 'IN'
    },
    IA: {
      label: 'Iowa',
      value: 'IA'
    },
    KS: {
      label: 'Kansas',
      value: 'KS'
    },
    KY: {
      label: 'Kentucky',
      value: 'KY'
    },
    LA: {
      label: 'Louisiana',
      value: 'LA'
    },
    ME: {
      label: 'Maine',
      value: 'ME'
    },
    MH: {
      label: 'Marshal Islands',
      value: 'MH'
    },
    MD: {
      label: 'Maryland',
      value: 'MD'
    },
    MA: {
      label: 'Massachusetts',
      value: 'MA'
    },
    MI: {
      label: 'Michigan',
      value: 'MI'
    },
    MN: {
      label: 'Minnesota',
      value: 'MN'
    },
    MS: {
      label: 'Mississippi',
      value: 'MS'
    },
    MO: {
      label: 'Missouri',
      value: 'MO'
    },
    MT: {
      label: 'Montana',
      value: 'MT'
    },
    NE: {
      label: 'Nebraska',
      value: 'NE'
    },
    NV: {
      label: 'Nevada',
      value: 'NV'
    },
    NH: {
      label: 'New Hampshire',
      value: 'NH'
    },
    NJ: {
      label: 'New Jersey',
      value: 'NJ'
    },
    NM: {
      label: 'New Mexico',
      value: 'NM'
    },
    NY: {
      label: 'New York',
      value: 'NY'
    },
    NC: {
      label: 'North Carolina',
      value: 'NC'
    },
    ND: {
      label: 'North Dakota',
      value: 'ND'
    },
    MP: {
      label: 'Northern Mariana Islands',
      value: 'MP'
    },
    OH: {
      label: 'Ohio',
      value: 'OH'
    },
    OK: {
      label: 'Oklahoma',
      value: 'OK'
    },
    OR: {
      label: 'Oregon',
      value: 'OR'
    },
    PW: {
      label: 'Palau',
      value: 'PW'
    },
    PA: {
      label: 'Pennsylvania',
      value: 'PA'
    },
    PR: {
      label: 'Puerto Rico',
      value: 'PR'
    },
    RI: {
      label: 'Rhode Island',
      value: 'RI'
    },
    SC: {
      label: 'South Carolina',
      value: 'SC'
    },
    SD: {
      label: 'South Dakota',
      value: 'SD'
    },
    TN: {
      label: 'Tennessee',
      value: 'TN'
    },
    TX: {
      label: 'Texas',
      value: 'TX'
    },
    UT: {
      label: 'Utah',
      value: 'UT'
    },
    VT: {
      label: 'Vermont',
      value: 'VT'
    },
    VI: {
      label: 'Virgin Islands',
      value: 'VI'
    },
    VA: {
      label: 'Virginia',
      value: 'VA'
    },
    WA: {
      label: 'Washington',
      value: 'WA'
    },
    WV: {
      label: 'West Virginia',
      value: 'WV'
    },
    WI: {
      label: 'Wisconsin',
      value: 'WI'
    },
    WY: {
      label: 'Wyoming',
      value: 'WY'
    }
  }
};
