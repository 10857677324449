const config = {
  development: {
    BASE_LTC_URL: 'https://sand-ltc.ixn.io',
    API_BASE_URL: 'https://sand-grapeshot.ixn.io',
    SA_BASE_URL: 'https://ixntestsa001.blob.core.windows.net',
    ANNUITIES_BASE_URL: 'https://sand-grapeshot.ixn.io',
    LIFEDX_EMBED_BASE_URL: 'https://sand-embed.ixn.io',
    BASE_WEB_DELETION_URL: 'https://sand-admiral.ixn.io/v1',
    ANICO_EMBED_TOKEN: '',
    ANICO_TEST_EMBED_TOKEN: 'f951535c-14d1-4b28-9987-ac1d876639fc',
    PACLIFE_EMBED_TOKEN: '',
    PACLIFE_TEST_EMBED_TOKEN: 'cd346478-ea6c-453b-aeb3-59bb8ee4306a',
    PROTECTIVE_EMBED_TOKEN: '',
    PROTECTIVE_TEST_EMBED_TOKEN: '9564ed22-d604-4576-813f-827a71f1d194',
    SBLI_EMBED_TOKEN: '',
    SBLI_TEST_EMBED_TOKEN: '2b13e362-afa4-4de6-9460-9b8e5ffd3645'
  },

  sand: {
    BASE_LTC_URL: 'https://sand-ltc.ixn.tech',
    API_BASE_URL: 'https://sand-grapeshot.ixn.tech',
    ANNUITIES_BASE_URL: 'https://sand-grapeshot.ixn.io',
    SA_BASE_URL: 'https://ixntestsa001.blob.core.windows.net',
    LIFEDX_EMBED_BASE_URL: 'https://sand-embed.thelifedx.com',
    BASE_WEB_DELETION_URL: 'https://sand-admiral.ixn.io/v1',
    ANICO_EMBED_TOKEN: '875d6977-f8d1-4fa8-9c30-c05d795256f7',
    ANICO_TEST_EMBED_TOKEN: 'e20a0a38-5981-4ce6-ac08-fa5f4b773e59',
    PACLIFE_EMBED_TOKEN: 'd2a1cf69-eaf8-4f4b-820e-313a11b0e4ac',
    PACLIFE_TEST_EMBED_TOKEN: '9880d92a-7147-421a-8bae-8d9bab5a9bf3',
    PROTECTIVE_EMBED_TOKEN: '92a16a61-2d9d-4018-a086-3eeefa65c2f6',
    PROTECTIVE_TEST_EMBED_TOKEN: 'a9260581-e6fe-4b37-9c88-3b48e405ee7f',
    SBLI_EMBED_TOKEN: '70b787af-5981-4638-8a98-99e997e1fa92',
    SBLI_TEST_EMBED_TOKEN: '05ed9229-4ff7-45b9-8f97-992db5c4ef5f'
  },

  qa: {
    BASE_LTC_URL: 'https://qa-ltc.ixn.tech',
    API_BASE_URL: 'https://qa-grapeshot.ixn.tech',
    ANNUITIES_BASE_URL: 'https://qa-grapeshot.ixn.io',
    SA_BASE_URL: 'https://ixnqasa001.blob.core.windows.net',
    LIFEDX_EMBED_BASE_URL: 'https://qa-embed.thelifedx.com',
    BASE_WEB_DELETION_URL: 'https://qa-admiral.ixn.io/v1',
    ANICO_EMBED_TOKEN: '875d6977-f8d1-4fa8-9c30-c05d795256f7',
    ANICO_TEST_EMBED_TOKEN: 'e20a0a38-5981-4ce6-ac08-fa5f4b773e59',
    PACLIFE_EMBED_TOKEN: 'd2a1cf69-eaf8-4f4b-820e-313a11b0e4ac',
    PACLIFE_TEST_EMBED_TOKEN: '9880d92a-7147-421a-8bae-8d9bab5a9bf3',
    PROTECTIVE_EMBED_TOKEN: '92a16a61-2d9d-4018-a086-3eeefa65c2f6',
    PROTECTIVE_TEST_EMBED_TOKEN: 'a9260581-e6fe-4b37-9c88-3b48e405ee7f',
    SBLI_EMBED_TOKEN: '70b787af-5981-4638-8a98-99e997e1fa92',
    SBLI_TEST_EMBED_TOKEN: '05ed9229-4ff7-45b9-8f97-992db5c4ef5f'
  },

  production: {
    BASE_LTC_URL: 'https://ltc.ixn.tech',
    API_BASE_URL: 'https://grapeshot.ixn.tech',
    ANNUITIES_BASE_URL: 'https://grapeshot.ixn.tech',
    SA_BASE_URL: 'https://ixnprodcontentncusa.blob.core.windows.net',
    LIFEDX_EMBED_BASE_URL: 'https://embed.thelifedx.com',
    BASE_WEB_DELETION_URL: 'https://admiral.ixn.io/v1',
    ANICO_EMBED_TOKEN: 'a4779995-a279-41e5-8014-2a24ea6d5596',
    ANICO_TEST_EMBED_TOKEN: '7b05ab40-f47b-4c33-9e64-4f1d01b87f21',
    PACLIFE_EMBED_TOKEN: '697e1e8a-f8cb-4420-8de5-ad7c0761ebac',
    PACLIFE_TEST_EMBED_TOKEN: '207eec69-634f-4fce-bf12-20b93e9c02b8',
    PROTECTIVE_EMBED_TOKEN: '5eaa7a94-9604-46fe-98c5-4d43573ef7df',
    PROTECTIVE_TEST_EMBED_TOKEN: '9ab03fb3-ab6a-4e9e-8c93-69dc4aacbacd',
    SBLI_EMBED_TOKEN: '8f8fe2cf-2f34-4cbe-9f42-f2640f2ad985',
    SBLI_TEST_EMBED_TOKEN: '064da040-bfa7-4205-8892-019fed6484ed'
  },

  azure_sand: {
    BASE_LTC_URL: 'https://sand-ltc.ixn.tech',
    API_BASE_URL: 'https://sand-grapeshot.ixn.tech',
    ANNUITIES_BASE_URL: 'https://sand-grapeshot.ixn.tech',
    SA_BASE_URL: 'https://ixntestsa001.blob.core.windows.net',
    LIFEDX_EMBED_BASE_URL: 'https://sand-embed.ixn.io',
    BASE_WEB_DELETION_URL: 'https://sand-admiral.ixn.io/v1',
    ANICO_EMBED_TOKEN: '875d6977-f8d1-4fa8-9c30-c05d795256f7',
    ANICO_TEST_EMBED_TOKEN: 'e20a0a38-5981-4ce6-ac08-fa5f4b773e59',
    PACLIFE_EMBED_TOKEN: 'd2a1cf69-eaf8-4f4b-820e-313a11b0e4ac',
    PACLIFE_TEST_EMBED_TOKEN: '9880d92a-7147-421a-8bae-8d9bab5a9bf3',
    PROTECTIVE_EMBED_TOKEN: '92a16a61-2d9d-4018-a086-3eeefa65c2f6',
    PROTECTIVE_TEST_EMBED_TOKEN: 'a9260581-e6fe-4b37-9c88-3b48e405ee7f',
    SBLI_EMBED_TOKEN: '70b787af-5981-4638-8a98-99e997e1fa92',
    SBLI_TEST_EMBED_TOKEN: '05ed9229-4ff7-45b9-8f97-992db5c4ef5f'
  },

  azure_qa: {
    BASE_LTC_URL: 'https://qa-ltc.ixn.tech',
    API_BASE_URL: 'https://qa-grapeshot.ixn.tech',
    ANNUITIES_BASE_URL: 'https://qa-grapeshot.ixn.tech',
    SA_BASE_URL: 'https://ixnqasa001.blob.core.windows.net',
    LIFEDX_EMBED_BASE_URL: 'https://qa-embed.ixn.io',
    BASE_WEB_DELETION_URL: 'https://qa-admiral.ixn.io/v1',
    ANICO_EMBED_TOKEN: '875d6977-f8d1-4fa8-9c30-c05d795256f7',
    ANICO_TEST_EMBED_TOKEN: 'e20a0a38-5981-4ce6-ac08-fa5f4b773e59',
    PACLIFE_EMBED_TOKEN: 'd2a1cf69-eaf8-4f4b-820e-313a11b0e4ac',
    PACLIFE_TEST_EMBED_TOKEN: '9880d92a-7147-421a-8bae-8d9bab5a9bf3',
    PROTECTIVE_EMBED_TOKEN: '92a16a61-2d9d-4018-a086-3eeefa65c2f6',
    PROTECTIVE_TEST_EMBED_TOKEN: 'a9260581-e6fe-4b37-9c88-3b48e405ee7f',
    SBLI_EMBED_TOKEN: '70b787af-5981-4638-8a98-99e997e1fa92',
    SBLI_TEST_EMBED_TOKEN: '05ed9229-4ff7-45b9-8f97-992db5c4ef5f'
  },

  azure_production: {
    BASE_LTC_URL: 'https://ltc.ixn.tech',
    API_BASE_URL: 'https://grapeshot.ixn.tech',
    ANNUITIES_BASE_URL: 'https://grapeshot.ixn.tech',
    SA_BASE_URL: 'https://ixnprodcontentncusa.blob.core.windows.net',
    LIFEDX_EMBED_BASE_URL: 'https://embed.ixn.io',
    BASE_WEB_DELETION_URL: 'https://admiral.ixn.io/v1',
    ANICO_EMBED_TOKEN: 'a4779995-a279-41e5-8014-2a24ea6d5596',
    ANICO_TEST_EMBED_TOKEN: '7b05ab40-f47b-4c33-9e64-4f1d01b87f21',
    PACLIFE_EMBED_TOKEN: '697e1e8a-f8cb-4420-8de5-ad7c0761ebac',
    PACLIFE_TEST_EMBED_TOKEN: '207eec69-634f-4fce-bf12-20b93e9c02b8',
    PROTECTIVE_EMBED_TOKEN: '5eaa7a94-9604-46fe-98c5-4d43573ef7df',
    PROTECTIVE_TEST_EMBED_TOKEN: '9ab03fb3-ab6a-4e9e-8c93-69dc4aacbacd',
    SBLI_EMBED_TOKEN: '8f8fe2cf-2f34-4cbe-9f42-f2640f2ad985',
    SBLI_TEST_EMBED_TOKEN: '064da040-bfa7-4205-8892-019fed6484ed'
  }
};

module.exports = config[process.env.NODE_ENV];
