import Axios from 'axios';

import AppConfig from 'config/App';

const token = window.IXN_QUOTER_CONFIG.id;
const request = Axios.create({
  baseURL: AppConfig.API_BASE_URL,
  headers: {
    Accept: 'application/json'
  }
});

const WebsiteQuoterApi = {
  loadWebsiteQuoter() {
    request.defaults.headers['WEBSITE-QUOTER-TOKEN'] = token;

    return request
      .get(`/v1/website_quoters/${token}`)
      .then(response => response.data)
      .catch(response => response.data);
  },

  verifyGoogleRecaptchaToken(captcha_token) {
    request.defaults.headers['WEBSITE-QUOTER-TOKEN'] = token;

    return request.post(`/v1/website_quoters/${token}/verify`, { captcha_token }).then(response => response.data);
  },

  getQuotes(params) {
    return request.post('/v1/quotes', params).then(response => response.data);
  },

  saveLead(lead) {
    return request.post('/v1/leads', lead).then(response => response.data);
  },

  updateLead(lead) {
    return request.put(`/v1/leads/${lead.id}`, lead).then(response => response.data);
  },

  loadAmbestRecords(carrier_id) {
    return request.get(`/v1/ambest_records?carrier_id=${carrier_id}`).then(response => response.data);
  }
};

export default WebsiteQuoterApi;
