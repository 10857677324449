import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'shared/Modal';

import Results from 'website_quoter/Results';

class ResultsModal extends React.Component {
  static propTypes = {
    Lead: PropTypes.object,
    onClose: PropTypes.func,
    quoteData: PropTypes.object,
    quoter: PropTypes.object,
    quotes: PropTypes.array,
    selectedConfig: PropTypes.object
  };

  render() {
    return (
      <Modal maxWidth={800} onClose={this.props.onClose} title='Your Results'>
        <Results
          Lead={this.props.Lead}
          onZeroStateClick={this.props.onClose}
          quoteData={this.props.quoteData}
          quoter={this.props.quoter}
          quotes={this.props.quotes}
          selectedConfig={this.props.selectedConfig}
        />
      </Modal>
    );
  }
}

export default ResultsModal;
