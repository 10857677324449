import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Toasts.css';

const Notification = ({ message, color, duration = 5000, position = 'top-right' }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  const getPositionStyles = () => {
    const [vertical, horizontal] = position.split('-');
    const styles = {};
    switch (vertical) {
      case 'top':
        styles.top = '1rem';
        break;
      case 'bottom':
        styles.bottom = '1rem';
        break;
      default:
        styles.bottom = '1rem';
        break;
    }
    switch (horizontal) {
      case 'left':
        styles.left = '1rem';
        break;
      case 'right':
        styles.right = '1rem';
        break;
      default:
        styles.right = '1rem';
        break;
    }
    styles.color = color;
    return styles;
  };

  return (
    <div className={`notification ${color} ${isVisible ? 'visible' : ''}`} style={getPositionStyles()}>
      {message}
    </div>
  );
};
Notification.propTypes = {
  color: PropTypes.string.isRequired,
  duration: PropTypes.number,
  message: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right'])
};

export default Notification;
