import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Loader from 'shared/Loader';

import { Colors, FontWeights, FontSizes, FontFamilies } from 'constants/Clementine';

class Modal extends React.Component {
  static propTypes = {
    buttons: PropTypes.array,
    id: PropTypes.string,
    children: PropTypes.node,
    fullScreen: PropTypes.bool,
    loading: PropTypes.bool,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClose: PropTypes.func,
    title: PropTypes.node
  };

  componentDidMount() {
    document.body.classList.add('ixn-no-scroll');

    document.onkeydown = e => {
      if (e.key === 'Escape') {
        this.props.onClose();
      }
    };
  }

  componentWillUnmount() {
    document.body.classList.remove('ixn-no-scroll');

    document.onkeydown = null;
  }

  render() {
    const { buttons, id, children, fullScreen, loading, maxWidth, onClose, title } = this.props;
    const styles = {
      modal: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
        background: 'rgba(0,0,0,0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      container: {
        maxWidth: fullScreen ? 'none' : maxWidth || 400,
        width: fullScreen ? 'calc(100vw - 40px)' : 'auto',
        borderRadius: 10,
        overflow: 'hidden'
      },
      header: {
        position: 'sticky',
        top: 0,
        padding: 20,
        borderBottom: '1px solid #e5e5e5',
        display: 'flex',
        alignItems: 'center',
        background: '#fff'
      },
      close: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center'
      },
      close_msg: {
        fontSize: 12,
        color: '#999'
      },
      close_icon: {
        marginLeft: 4,
        fontSize: 30,
        color: Colors.GRAY.hex,
        cursor: 'pointer'
      },
      modal_content_wrapper: {
        overflow: 'auto',
        background: '#fff',
        height: fullScreen ? 'calc(100vh - 110px)' : 'auto',
        maxHeight: fullScreen ? 'calc(100vh - 110px)' : 'calc(100vh - 260px)'
      },
      modal_content: {
        margin: '0 auto',
        maxWidth: maxWidth || 1400
      },
      title: {
        fontFamily: FontFamilies.ACCENT,
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.MEDIUM
      },
      button_bar: {
        position: 'sticky',
        bottom: 0,
        background: '#fff',
        padding: 20,
        borderTop: '1px solid #e5e5e5',
        display: 'flex'
      }
    };

    return (
      <div id={id} style={styles.modal}>
        <div style={styles.container}>
          <div style={styles.header}>
            {title ? <div style={styles.title}>{title}</div> : null}
            {onClose ? (
              <div style={styles.close}>
                <span style={styles.close_msg}>(Esc)</span>
                <i className='mdi mdi-close' onClick={onClose} style={styles.close_icon} />
              </div>
            ) : null}
          </div>
          <div style={styles.modal_content_wrapper}>
            <div style={styles.modal_content}>{loading ? <Loader /> : children}</div>
          </div>
          {buttons ? (
            <div style={styles.button_bar}>
              {buttons.map((b, i) => (
                <Button key={i} {...b} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Modal;
