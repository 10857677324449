import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'shared/ThemeContext';

import { Colors, FontSizes } from 'constants/Clementine';

class Tooltip extends React.Component {
  static propTypes = {
    error: PropTypes.bool,
    errorMessage: PropTypes.node,
    position: PropTypes.oneOf(['left', 'right', 'left-top']),
    width: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  _toggleHover = hovering => {
    this.setState({ hovering });
  };

  render() {
    const { children, error } = this.props;
    const styles = this.styles();

    return (
      <div onMouseEnter={this._toggleHover.bind(null, true)} onMouseLeave={this._toggleHover.bind(null, false)} style={styles.component}>
        <i className={(error && 'mdi mdi-alert-octagon') || 'mdi mdi-information-outline'} style={styles.icon} />

        {this.state.hovering ? (
          <div style={styles.content}>
            <div style={styles.arrow} />
            {children}
          </div>
        ) : null}
      </div>
    );
  }

  styles = () => {
    const { error, position = 'right', width } = this.props;
    const color = Colors.GRAY.hex;
    const align_left = position.includes('left');
    const align_top = position.includes('top');

    return {
      component: {
        display: 'inline-block',
        marginLeft: error && 'auto',
        position: 'relative'
      },
      content: {
        background: color,
        color: '#fff',
        fontSize: FontSizes.SMALL,
        padding: 20,
        position: 'absolute',
        zIndex: 2,
        top: align_top ? 0 : '50%',
        right: align_left ? 'auto' : -8,
        left: align_left ? -4 : 'auto',
        transform: `translate(${align_left ? '-100%' : '100%'}, ${align_top ? '-8px' : '-50%'})`,
        borderRadius: 4,
        width: width || 200,
        boxShadow: '0px 20px 30px -15px rgba(0,0,0,0.5)',
        textAlign: 'left',
        lineHeight: '1.4em',
        whiteSpace: 'normal'
      },
      icon: {
        color: (error && Colors.RED.hex) || color,
        fontSize: (error && 15) || 14,
        lineHeight: 1,
        margin: '0px 0px 0px 5px'
      },
      arrow: {
        width: 0,
        height: 0,
        borderTop: '6px solid transparent',
        borderBottom: '6px solid transparent',
        borderRight: align_left ? 'none' : `6px solid ${color}`,
        borderLeft: align_left ? `6px solid ${color}` : 'none',
        position: 'absolute',
        top: align_top ? 0 : '50%',
        left: align_left ? 'auto' : 0,
        right: align_left ? 0 : 'auto',
        transform: `translate(${align_left ? '100%' : '-100%'}, ${align_top ? '8px' : '-50%'})`
      }
    };
  };
}

Tooltip.contextType = ThemeContext;

export default Tooltip;
