import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { Colors, FontSizes } from 'constants/Clementine';

import Select from 'shared/Select';

class DatePicker extends React.Component {
  static propTypes = {
    hidePlaceholders: PropTypes.bool,
    id: PropTypes.string,
    invalid: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string
  };

  constructor(props) {
    super(props);

    let value_m = null;

    if (props.value && props.value !== 'Invalid date') {
      const date = new Date(props.value);
      if (!isNaN(date)) {
        value_m = Moment(date);
      } else {
        console.warn('Date is not in a recognized RFC2822 or ISO format');
      }
    }

    this.state = {
      month: value_m ? value_m.format('MM') : '',
      day: value_m ? value_m.format('DD') : '',
      year: value_m ? value_m.format('YYYY') : ''
    };
  }

  _generateMonths = () => {
    const options = this.props.hidePlaceholders ? [] : [{ value: '', label: '--' }];

    return options.concat([
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' }
    ]);
  };

  _generateDays = () => {
    const days = [];
    const year = this.state.year || '3000';
    const num_days = Moment(`${year}-${this.state.month}-01`).daysInMonth();
    const options = this.props.hidePlaceholders ? [] : [{ value: '', label: '--' }];

    for (let i = 1; i <= num_days; i++) {
      days.push(i);
    }

    return options.concat(
      days.map(d => {
        return {
          value: d < 10 ? `0${d}` : `${d}`,
          label: d
        };
      })
    );
  };

  _generateYears = () => {
    const years = [];
    const this_year = parseInt(Moment().format('YYYY'), 10);
    const options = this.props.hidePlaceholders ? [] : [{ value: '', label: '--' }];

    for (let i = this_year - 120; i <= this_year; i++) {
      years.push(i);
    }

    return options.concat(years.map(y => ({ value: `${y}` })));
  };

  _handleMonthChange = e => {
    const year = this.state.year || '3000';
    const num_days = Moment(`${year}-${this.state.month}-01`).daysInMonth();

    this.setState({
      month: e.target.value,
      day: !e.target.value || this.state.day > num_days ? '' : this.state.day
    });

    this.props.onChange(`${this.state.year}-${e.target.value}-${this.state.day}`);
  };

  _handleDayChange = e => {
    this.setState({
      day: e.target.value
    });

    this.props.onChange(`${this.state.year}-${this.state.month}-${e.target.value}`);
  };

  _handleYearChange = e => {
    this.setState({
      year: e.target.value
    });

    this.props.onChange(`${e.target.value}-${this.state.month}-${this.state.day}`);
  };

  render() {
    const { readOnly, id, required } = this.props;
    const styles = this.styles();

    return (
      <div id={id} style={styles.component}>
        <Select
          id='date-picker-month'
          invalid={this.props.invalid}
          onChange={this._handleMonthChange}
          options={this._generateMonths()}
          readOnly={readOnly}
          style={{ marginRight: -1, borderRadius: '4px 0px 0px 4px', minWidth: 110 }}
          value={this.state.month}
        />
        <Select
          id='date-picker-days'
          invalid={this.props.invalid}
          onChange={this._handleDayChange}
          options={this._generateDays()}
          readOnly={readOnly}
          style={{ marginRight: -1, borderRadius: 0, minWidth: 50 }}
          value={this.state.day}
        />
        <Select
          id='date-picker-year'
          invalid={this.props.invalid}
          onChange={this._handleYearChange}
          options={this._generateYears()}
          readOnly={readOnly}
          style={{ marginRight: -1, borderRadius: '0px 4px 4px 0px', minWidth: 75 }}
          value={this.state.year}
        />
        {required ? <span style={styles.required}>*</span> : null}
      </div>
    );
  }

  styles = () => {
    return {
      component: Object.assign(
        {
          display: 'flex',
          position: 'relative'
        },
        this.props.style
      ),
      required: {
        color: Colors.RED.hex,
        position: 'absolute',
        right: 5,
        top: 5,
        lineHeight: 1,
        fontSize: FontSizes.LARGE
      }
    };
  };
}

export default DatePicker;
