import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Numeral from 'numeral';

import Button from 'shared/Button';

import { isMobile } from 'utils/Browser';

import { FontSizes, FontWeights } from 'constants/Clementine';

import { ThemeContext } from 'shared/ThemeContext';

import { SA_BASE_URL } from 'config/App';

class Quote extends React.Component {
  static propTypes = {
    onAmbestClick: PropTypes.func,
    onButtonClick: PropTypes.func,
    quote: PropTypes.object.isRequired,
    quoter: PropTypes.object,
    selectedConfig: PropTypes.object,
    sortBy: PropTypes.object
  };

  _getQuoteButtons = () => {
    let buttons = [];
    const { crm, lead_form_btn_label } = this.props.quoter;

    if (crm === 'insureio') {
      buttons = [
        {
          label: lead_form_btn_label,
          action_type: 'insureio'
        }
      ];
    } else if (crm === 'smart_office') {
      buttons = [
        {
          label: lead_form_btn_label,
          action_type: 'smart_office'
        }
      ];
    } else if (crm === 'ixn') {
      buttons = [
        {
          label: lead_form_btn_label,
          action_type: 'ixn_lead_form'
        }
      ];
    } else if (crm === 'custom') {
      buttons = this.props.quoter.custom_actions.filter(action => {
        const carrier_ids = action.carrier_ids || [];
        const product_ids = action.product_ids || [];
        const matches_carrier = !carrier_ids.length || carrier_ids.indexOf(this.props.quote.carrier_id) > -1;
        const matches_product = !product_ids.length || product_ids.indexOf(this.props.quote.product_id) > -1;

        return matches_carrier && matches_product;
      });
    }

    return buttons;
  };

  render() {
    const styles = this.styles();
    const quote = this.props.quote;
    const buttons = this._getQuoteButtons();

    return (
      <div style={styles.component}>
        <div style={styles.quote}>
          <div
            style={Object.assign(
              {},
              styles.company,
              quote.carrier_id
                ? {
                    backgroundImage: `url(${SA_BASE_URL}/ixn-data/logos/carrier_` + quote.carrier_id + '_medium.png)'
                  }
                : null
            )}
          >
            {!quote.carrier_id ? quote.carrier_name : null}
          </div>
          <div style={styles.product}>
            <div style={styles.product_name}>{quote.product_name}</div>({quote.carrier_health_category})
          </div>
          <div style={styles.premium}>
            {['monthly', 'quarterly', 'semi_annual', 'annual'].map(payment_mode => {
              return (
                <div key={payment_mode} style={styles.premium_total}>
                  <span style={styles.premium_interval}>{payment_mode.replace('_', ' ')}:</span>
                  {quote[`premium_${payment_mode}`] !== 0 ? (
                    <span style={styles.premium_amount}> {Numeral(quote[`premium_${payment_mode}`]).format('$0,0.00')}</span>
                  ) : (
                    <span style={styles.premium_amount}> N/A</span>
                  )}
                </div>
              );
            })}
          </div>
          {!isMobile ? (
            <div onClick={this.props.onAmbestClick.bind(null, this.props.quote)} style={styles.rating}>
              <text style={styles.am_best}>{quote.am_best_rating}</text>
              <div style={styles.rating_date}>{quote.am_best_date ? Moment(quote.am_best_date, 'YYYY-MM-DD').format('MMM Do, YYYY') : ''}</div>
            </div>
          ) : null}

          {buttons.map((button, i) => {
            return (
              <Button key={i} onClick={this.props.onButtonClick.bind(null, button, this.props.quote)} style={styles.button}>
                {button.label}
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      component: {
        backgroundColor: '#fff',
        borderRadius: 5,
        marginBottom: 10,
        padding: 20,
        border: '1px solid #e5e5e5',
        position: 'relative',
        overflow: 'hidden'
      },
      quote: {
        display: isMobile ? 'block' : 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        textAlign: isMobile ? 'center' : 'inherit'
      },
      company: {
        width: isMobile ? '100%' : '20%',
        height: 40,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        marginBottom: isMobile ? 20 : 0,
        textAlign: 'center',
        fontWeight: 600
      },
      premium: {
        width: isMobile ? '100%' : '30%',
        textAlign: isMobile ? 'center' : 'right'
      },
      premium_total: {
        padding: '5px 0'
      },
      premium_amount: {
        fontWeight: 600,
        fontSize: FontSizes.LARGE
      },
      premium_interval: {
        textTransform: 'capitalize'
      },
      product: {
        width: isMobile ? '100%' : '25%',
        lineHeight: '1.3em',
        marginBottom: isMobile ? 15 : 0,
        paddingLeft: isMobile ? 0 : 20
      },
      product_name: {
        fontSize: FontSizes.LARGE,
        fontWeight: 600,
        marginBottom: 5
      },
      rating: {
        textAlign: 'center',
        fontSize: FontSizes.XLARGE,
        width: '20%',
        cursor: 'pointer'
      },
      am_best: {
        color: this.context.color,
        fontSize: FontSizes.XLARGE,
        fontWeight: FontWeights.BOLD 
      },
      rating_date: {
        fontSize: FontSizes.SMALL,
        marginTop: 5
      },
      button: {
        marginLeft: 10,
        marginTop: isMobile ? 10 : 0,
        width: isMobile ? '100%' : 'auto',
        flexShrink: 0
      }
    };
  };
}

Quote.contextType = ThemeContext;

export default Quote;
