import React from 'react';
import PropTypes from 'prop-types';

import { Colors, FontSizes } from 'constants/Clementine';

class FlashMessage extends React.Component {
  static propTypes = {
    isPinned: PropTypes.bool,
    type: PropTypes.oneOf(['error'])
  };

  render() {
    const styles = this.styles();

    return (
      <div {...this.props} style={Object.assign({}, styles.component, styles[this.props.type || 'error'], this.props.style)}>
        {this.props.children}
      </div>
    );
  }

  styles = () => {
    return {
      component: Object.assign(
        {
          padding: this.props.isPinned ? '20px 40px' : 10,
          borderRadius: this.props.isPinned ? 0 : 3,
          marginBottom: 10,
          fontSize: FontSizes.REGULAR,
          fontWeight: 600,
          position: this.props.isPinned ? 'absolute' : 'relative',
          top: this.props.isPinned ? 0 : 'none',
          left: this.props.isPinned ? 0 : 'none',
          right: this.props.isPinned ? 0 : 'none',
          zIndex: this.props.isPinned ? 10 : 'none',
          opacity: this.props.isPinned ? 0.9 : 1
        },
        this.props.style
      ),
      error: {
        backgroundColor: Colors.RED.hex,
        color: '#fff'
      }
    };
  };
}

export default FlashMessage;
