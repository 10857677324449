module.exports = {
  MONTHS: [
    {
      value: '01',
      label: 'January'
    },
    {
      value: '02',
      label: 'February'
    },
    {
      value: '03',
      label: 'March'
    },
    {
      value: '04',
      label: 'April'
    },
    {
      value: '05',
      label: 'May'
    },
    {
      value: '06',
      label: 'June'
    },
    {
      value: '07',
      label: 'July'
    },
    {
      value: '08',
      label: 'August'
    },
    {
      value: '09',
      label: 'September'
    },
    {
      value: '10',
      label: 'October'
    },
    {
      value: '11',
      label: 'November'
    },
    {
      value: '12',
      label: 'December'
    }
  ],
  STATES: [
    {
      label: 'Alabama',
      value: 'AL'
    },
    {
      label: 'Alaska',
      value: 'AK'
    },
    {
      label: 'American Samoa',
      value: 'AS'
    },
    {
      label: 'Arizona',
      value: 'AZ'
    },
    {
      label: 'Arkansas',
      value: 'AR'
    },
    {
      label: 'California',
      value: 'CA'
    },
    {
      label: 'Colorado',
      value: 'CO'
    },
    {
      label: 'Connecticut',
      value: 'CT'
    },
    {
      label: 'Delaware',
      value: 'DE'
    },
    {
      label: 'District of Columbia',
      value: 'DC'
    },
    {
      label: 'Federated States of Micronesia',
      value: 'FM'
    },
    {
      label: 'Florida',
      value: 'FL'
    },
    {
      label: 'Georgia',
      value: 'GA'
    },
    {
      label: 'Guam',
      value: 'GU'
    },
    {
      label: 'Hawaii',
      value: 'HI'
    },
    {
      label: 'Idaho',
      value: 'ID'
    },
    {
      label: 'Illinois',
      value: 'IL'
    },
    {
      label: 'Indiana',
      value: 'IN'
    },
    {
      label: 'Iowa',
      value: 'IA'
    },
    {
      label: 'Kansas',
      value: 'KS'
    },
    {
      label: 'Kentucky',
      value: 'KY'
    },
    {
      label: 'Louisiana',
      value: 'LA'
    },
    {
      label: 'Maine',
      value: 'ME'
    },
    {
      label: 'Maryland',
      value: 'MD'
    },
    {
      label: 'Massachusetts',
      value: 'MA'
    },
    {
      label: 'Michigan',
      value: 'MI'
    },
    {
      label: 'Minnesota',
      value: 'MN'
    },
    {
      label: 'Mississippi',
      value: 'MS'
    },
    {
      label: 'Missouri',
      value: 'MO'
    },
    {
      label: 'Montana',
      value: 'MT'
    },
    {
      label: 'Nebraska',
      value: 'NE'
    },
    {
      label: 'Nevada',
      value: 'NV'
    },
    {
      label: 'New Hampshire',
      value: 'NH'
    },
    {
      label: 'New Jersey',
      value: 'NJ'
    },
    {
      label: 'New Mexico',
      value: 'NM'
    },
    {
      label: 'New York',
      value: 'NY'
    },
    {
      label: 'North Carolina',
      value: 'NC'
    },
    {
      label: 'North Dakota',
      value: 'ND'
    },
    {
      label: 'Northern Mariana Islands',
      value: 'MP'
    },
    {
      label: 'Ohio',
      value: 'OH'
    },
    {
      label: 'Oklahoma',
      value: 'OK'
    },
    {
      label: 'Oregon',
      value: 'OR'
    },
    {
      label: 'Palau',
      value: 'PW'
    },
    {
      label: 'Pennsylvania',
      value: 'PA'
    },
    {
      label: 'Puerto Rico',
      value: 'PR'
    },
    {
      label: 'Rhode Island',
      value: 'RI'
    },
    {
      label: 'South Carolina',
      value: 'SC'
    },
    {
      label: 'South Dakota',
      value: 'SD'
    },
    {
      label: 'Tennessee',
      value: 'TN'
    },
    {
      label: 'Texas',
      value: 'TX'
    },
    {
      label: 'Utah',
      value: 'UT'
    },
    {
      label: 'Vermont',
      value: 'VT'
    },
    {
      label: 'Virginia',
      value: 'VA'
    },
    {
      label: 'Washington',
      value: 'WA'
    },
    {
      label: 'West Virginia',
      value: 'WV'
    },
    {
      label: 'Wisconsin',
      value: 'WI'
    },
    {
      label: 'Wyoming',
      value: 'WY'
    }
  ],
  GENDERS: [
    {
      label: 'Male',
      value: 'Male'
    },
    {
      label: 'Female',
      value: 'Female'
    }
  ],
  AGE_TYPES: [
    {
      label: 'Age',
      value: 'Age'
    },
    {
      label: 'DOB',
      value: 'DOB'
    }
  ],
  TOBACCO_OPTIONS: [
    {
      label: 'No',
      value: false
    },
    {
      label: 'Yes',
      value: true
    }
  ],
  NON_TOBACCO: ['Preferred Plus', 'Preferred', 'Standard Plus', 'Standard'],
  TOBACCO: ['Preferred', 'Standard'],
  RIDERS: ['Accidental Death Benefit', 'Waiver of Premium'],
  SORT_OPTIONS: [
    {
      value: 'premium_annual',
      label: 'Premium: Annual'
    },
    {
      value: 'premium_semi_annual',
      label: 'Premium: Semi-Annual'
    },
    {
      value: 'premium_quarterly',
      label: 'Premium: Quarterly'
    },
    {
      value: 'premium_monthly',
      label: 'Premium: Monthly'
    },
    {
      value: 'carrier_name',
      label: 'Carrier Name'
    }
  ],
  SORT_DIRECTION: [
    {
      value: 'asc',
      label: 'Ascending'
    },
    {
      value: 'desc',
      label: 'Descending'
    }
  ],
  CHILD_RIDER_UNITS: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
  TABLES: [
    'Table A',
    'Table B',
    'Table C',
    'Table D',
    'Table E',
    'Table F',
    'Table G',
    'Table H',
    'Table I',
    'Table J',
    'Table K',
    'Table L',
    'Table M',
    'Table N',
    'Table O',
    'Table P',
    'Table Q',
    'Table R',
    'Table S',
    'Table T',
    'Table U',
    'Table V'
  ],
  PAYMENT_MODES: [
    {
      value: 'monthly',
      label: 'Monthly'
    },
    {
      value: 'quarterly',
      label: 'Quarterly'
    },
    {
      value: 'semi_annual',
      label: 'Semi Annually'
    },
    {
      value: 'annual',
      label: 'Annually'
    }
  ],
  CUSTOM_ACTIONS: {
    // INSTANT_ISSUE: [
    //   'ethos'
    // ],
    ETICKETS: ['applicint', 'anico', 'nmb_term_eticket', 'nmb_whole_life_eticket', 'paclife', 'protective', 'sbli']
  },
  AMBEST_SORTING: {
    'A++': 13,
    'A+': 12,
    A: 11,
    'A-': 10,
    'B++': 9,
    'B+': 8,
    B: 7,
    'B-': 6,
    'C++': 5,
    'C+': 4,
    C: 3,
    'C-': 2,
    D: 1
  }
};
