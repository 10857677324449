import React from 'react';
import PropTypes from 'prop-types';

import { FontSizes } from 'constants/Clementine';

import { ThemeContext } from 'shared/ThemeContext';

class Header extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['primary', 'secondary'])
  };

  render() {
    const styles = this.styles();

    return (
      <div onClick={this.props.onClick || null} style={Object.assign({}, styles[this.props.type || 'primary'], this.props.style)}>
        {this.props.children}
      </div>
    );
  }

  styles = () => {
    return {
      primary: Object.assign(
        {},
        {
          fontSize: FontSizes.LARGE,
          backgroundColor: this.context.accent_color,
          color: '#fff',
          padding: 10
        },
        this.props.style
      ),
      secondary: {
        fontSize: FontSizes.LARGE,
        paddingBottom: 5,
        marginBottom: 15,
        borderBottom: '1px solid #CCCCCC'
      }
    };
  };
}

Header.contextType = ThemeContext;

export default Header;
