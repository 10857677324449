import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'shared/Tooltip';

import { ThemeContext } from 'shared/ThemeContext';

import { Colors, FontWeights } from 'constants/Clementine';

const Field = ({ action, id = 'field-text', children, invalid, label, style, tooltip, tooltipError, tooltipErrorMsg, tooltipErrorWidth, tooltipPosition, tooltipWidth }) => {
  const context = React.useContext(ThemeContext);
  const styles = {
    component: Object.assign(
      {},
      {
        marginBottom: 15
      },
      style
    ),
    label_wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      lineHeight: 1,
      marginBottom: 6
    },
    label: {
      color: invalid ? Colors.RED.hex : Colors.GRAY.hex,
      display: 'flex',
      fontSize: 12,
      lineHeight: '16px',
      verticalAlign: 'center'
    },
    action: {
      color: context.color,
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: FontWeights.MEDIUM,
      lineHeight: '16px'
    }
  };

  return (
    <div id={id} style={styles.component}>
      {label ? (
        <div style={styles.label_wrapper}>
          <div style={styles.label}>
            {label}
            {tooltip ? (
              <Tooltip position={tooltipPosition} width={tooltipWidth}>
                {tooltip}
              </Tooltip>
            ) : null}
            {tooltipError ? (
              <Tooltip error={tooltipError} position={tooltipPosition} width={tooltipErrorWidth}>
                {tooltipErrorMsg}
              </Tooltip>
            ) : null}
          </div>
          {action ? (
            <span onClick={action.onClick} style={styles.action}>
              {action.label}
            </span>
          ) : null}
        </div>
      ) : null}
      {children}
    </div>
  );
};

Field.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func
  }),
  invalid: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipError: PropTypes.bool,
  tooltipErrorMsg: PropTypes.node,
  tooltipErrorWidth: PropTypes.number,
  tooltipPosition: PropTypes.oneOf(['left', 'right']),
  tooltipWidth: PropTypes.number
};

export default Field;
