import Moment from 'moment';

export default {
  _getAgesFromDob(dob) {
    let calculated_current_age = null;
    let calculated_nearest_age = null;

    if (dob) {
      // Note: Moment accounts for leap years automatically
      const birth_date_this_year = Moment(dob).year(Moment().year());
      const next_birth_date = Moment().isBefore(birth_date_this_year) ? birth_date_this_year : birth_date_this_year.add(1, 'years');

      calculated_current_age = Moment().diff(dob, 'years');
      calculated_nearest_age = next_birth_date.isBefore(Moment().add(6, 'months')) ? calculated_current_age + 1 : calculated_current_age;
    }

    return { calculated_nearest_age, calculated_current_age };
  },

  _getDaysForMonth(month) {
    const month_number = parseInt(month, 10);
    const days = [];
    let max_days = 31;
    let i = 1;

    if ([4, 6, 9, 11].includes(month_number)) {
      max_days = 30;
    }

    if (month_number === 2) {
      max_days = 29;
    }

    for (i; i <= max_days; i++) {
      days.push({ value: i });
    }

    return days;
  },

  _getYears() {
    const years = [];
    let i = 1916;

    for (i; i < 2021; i++) {
      years.push({ value: i });
    }

    return years;
  },

  _validateEmail(email) {
    const email_pattern = new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

    return email_pattern.test(email);
  },

  _validatePhone(phone) {
    const number_pattern = new RegExp(/^\(?[1-9]\d{2}\)? ?\d{3}-?\d{4}$/);

    return number_pattern.test(phone);
  },

  _formatAsPhone(number) {
    let formatted_value = `${number}`.replace(/[^0-9.]/g, '');
    const size = formatted_value.length;

    if (size < 1) {
      formatted_value = formatted_value;
    } else if (size < 4) {
      formatted_value = `(${formatted_value}`;
    } else if (size < 7) {
      formatted_value = `(${formatted_value.substring(0, 3)}) ${formatted_value.substring(3, 6)}`;
    } else {
      formatted_value = `(${formatted_value.substring(0, 3)}) ${formatted_value.substring(3, 6)}-${formatted_value.substring(6, 10)}`;
    }

    return formatted_value;
  },

  _formatAsSsn(number) {
    let formatted_value = `${number}`.replace(/[^0-9.]/g, '');
    const size = formatted_value.length;

    if (size > 3 && size < 6) {
      formatted_value = `${formatted_value.substring(0, 3)}-${formatted_value.substring(3, 5)}`;
    } else if (size > 5) {
      formatted_value = `${formatted_value.substring(0, 3)}-${formatted_value.substring(3, 5)}-${formatted_value.substring(5, 9)}`;
    }

    return formatted_value;
  }
};
